import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AppContext, API } from "../../utils";
import toasts from "react-hot-toast";

import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";

import moment from "moment";
import dayjs from "dayjs";

const GoogleDataTable = () => {
  const [state, setState] = React.useContext(AppContext);
  const [status, setStatus] = useState("");

  const [ads, setAds] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const isMounted = useRef(false);

  const [dates2, setDates2] = useState(
    state.reportingPeriod || [
      dayjs().subtract(1, "day").toDate(),
      dayjs().toDate(),
    ]
  );


  const loadAds = async () => {
    setStatus("loading");
    try {
      const res = await API.google.ads(
        dayjs(state.reportingPeriod[0]).format("YYYY-MM-DD"),
        dayjs(state.reportingPeriod[1]).format("YYYY-MM-DD")
      );
      setAds(res.data);
      setStatus("");
    } catch (e) {
      console.log(e);
      toasts("There have been too many calls from this ad-account");
    }
  };

  ads.forEach((el) => {
    el.id = el.ad_group_ad.ad.id;
  });

  useEffect(() => {
    isMounted.current = true;
    // console.log("state", state);

    if (state.account && state.loaded) {
      (async () => {
        loadAds();
      })();
    }


    // setState({
    //   ...state,
    //   reportingPeriod: [dayjs().subtract(1, "day").toDate(), dayjs().toDate()],
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log("google", ads);

  const onRowExpand = (event) => {
    console.log(event.data.ad_group_ad.ad.id, "event");
    toast.current.show({
      severity: "info",
      summary: "Ad Expanded",
      detail: event.data.ad_group_ad.ad.name,
      life: 3000,
    });
  };

  const onRowCollapse = (event) => {
    toast.current.show({
      severity: "success",
      summary: "Ad Collapsed",
      detail: event.data.ad_group_ad.ad.name,
      life: 3000,
    });
  };

  const expandAll = () => {
    let _expandedRows = {};
    ads.forEach((p) => (_expandedRows[`${p.id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const adNameTemplate = (rowData) => {
    return <h1 className="font-bold text-sm">{rowData.ad_group_ad.ad.name}</h1>;
  };

  const imageBodyTemplate = (rowData) => {
    if (!rowData.ad_group_ad.ad.image_ad) return <h1>-</h1>;
    return (
      <img
        src={`${rowData.ad_group_ad.ad.image_ad.preview_image_url}`}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.ad_group_ad.ad.image_ad?.preview_image_url}
        className="product-image rounded-md cursor-pointer h-16 w-20"
      />
    );
  };

  const creationTimeTemplate = (rowData) => {
    return (
      <h1>{moment(new Date(rowData.segments.date)).format("MMMM Do YYYY")}</h1>
    );
  };

  const statusAdTemplate = (rowData) => {
    return (
      <span className="inline capitalize">
        {rowData.ad_group_ad.status == "2" ? (
          <span className="ml-2 font-bold text-sm">
            <Badge severity="success"></Badge> {"Enabled"}
          </span>
        ) : (
          <span className="ml-2 font-bold text-sm">
            <Badge severity="danger"></Badge> {"Paused"}
          </span>
        )}
      </span>
    );
  };

  const adTypeTemplate = (rowData) => {
    return (
      <span className="inline capitalize">
        {rowData.ad_group_ad.ad.type == "14" ? (
          <span className="ml-2 font-bold text-sm">{"Image ad"}</span>
        ) : (
          <span className="ml-2 font-bold text-sm">{"Video ad"}</span>
        )}
      </span>
    );
  };

  const displayUrlTemplate = (rowData) => {
    return (
      <a
        className="font-bold text-sm hover:text-orange-500  truncate"
        href={`https://${rowData.ad_group_ad.ad.display_url}`}
        target="_blank"
      >
        {rowData.ad_group_ad.ad.display_url}
      </a>
    );
  };

  const clicksTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.metrics ? rowData.metrics.clicks : "-"}
      </h1>
    );
  };

  const ctrTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.metrics.ctr ? `${(rowData.metrics.ctr * 1).toFixed(4)} ` : "-"}
      </h1>
    );
  };

  const impressionsTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.metrics.impressions ? rowData.metrics.impressions : "-"}
      </h1>
    );
  };

  const interactionsTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.metrics.interactions ? rowData.metrics.interactions : "-"}
      </h1>
    );
  };

  const interactionsRateTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.metrics.interaction_rate
          ? `${(rowData.metrics.interaction_rate * 1).toFixed(4)} %`
          : "-"}
      </h1>
    );
  };
  const videoViewsTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.metrics.video_views ? rowData.metrics.video_views : "-"}
      </h1>
    );
  };

  const header = (
    <div className="table-header-container flex gap-4">
      <Button
        icon="pi pi-plus"
        label="Expand All"
        onClick={expandAll}
        className="mr-2"
      />
      <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} />
      <div className="flex gap-4 table-header absolute right-7">
        <div className="field col-12 md:col-4">
          <Calendar
            dateFormat="yy/mm/dd"
            showIcon
            id="range"
            value={dates2}
            onChange={(e) => {
              setDates2(e.value);
              if (e.value[0] != null && e.value[1] != null)
                setState({ ...state, reportingPeriod: e.value });
            }}
            selectionMode="range"
            readOnlyInput
          />
        </div>
        <Button onClick={loadAds} icon="pi pi-refresh" />
      </div>
    </div>
  );

  const rowExpansionTemplate = (data) => {
    // console.log("data", data);
    return (
      <div className="orders-subtabl">
        <h5 className="text-orange-500 font-bold text-xl mb-2">
          Ads details for {data.ad_group_ad.ad.name}
        </h5>
        <DataTable value={[data]} responsiveLayout="scroll">
          {/* <Column  field="data.ads.name" header="Impressions" body={data.insights.impressions ? data.insights.impressions : "-"}></Column> */}
          <Column
            headerClassName="w-72"
            field="campaign.name"
            header="Campaign name"
            body={data.campaign ? data.campaign.name : "-"}
          ></Column>
          <Column
            headerClassName="w-72"
            field="ad_group.name"
            header="Ad Group name"
            body={data.ad_group.name ? data.ad_group.name : "-"}
          ></Column>
          <Column
            headerClassName="w-72"
            field="metrics.engagements"
            header="Engagements"
            body={data.metrics.engagements ? data.metrics.engagements : "-"}
          ></Column>
          <Column
            headerClassName="w-72"
            field="ad.engagement_rate"
            header="Engagement rate"
            body={
              data.metrics.engagement_rate ? (data.metrics.engagement_rate).toFixed(4) : "-"
            }
          ></Column>
        </DataTable>
      </div>
    );
  };

  return (
    <div className="datatable-rowexpansion-demo">
      <Toast ref={toast} />

      <div className="card">
        {status == "loading" ? (
          <>
            {" "}
            <ProgressBar
              color="rgb(249 115 22)"
              mode="indeterminate"
              style={{ height: "2px" }}
            ></ProgressBar>
            <p className="text-center text-orange-500 mt-2 font-bold">
              {" "}
              Loading data ...
            </p>
          </>
        ) : (
          <DataTable
            paginator
            className="border-t border-orange-500 overflow-y-auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={5}
            rowsPerPageOptions={[5, 25, 70]}
            value={ads}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            header={header}
          >
            <Column expander style={{ width: "3em" }} />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="ad_group_ad.ad.name"
              header="Ad name"
              headerClassName="w-64"
              sortable
              body={adNameTemplate}
            />
            <Column header="Ad image" body={imageBodyTemplate} />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"segments.date"}
              header="Created date"
              sortable
              body={creationTimeTemplate}
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="ad_group_ad.status"
              header="Status"
              sortable
              body={statusAdTemplate}
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="ad_group_ad.ad.type"
              header="Ad type"
              sortable
              body={adTypeTemplate}
            />
            <Column
              field="ad_group_ad.ad.display_url"
              header="Display Url"
              body={displayUrlTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"metrics.clicks"}
              header="Clicks"
              body={clicksTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"metrics.ctr"}
              header="Ctr"
              body={ctrTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"metrics.impressions"}
              header="Impressions"
              body={impressionsTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"metrics.interactions"}
              header="Interactions"
              body={interactionsTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"metrics.interaction_rate"}
              header="Interaction rate"
              body={interactionsRateTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"metrics.video_views"}
              header="Video views"
              body={videoViewsTemplate}
              sortable
            />
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default GoogleDataTable;
