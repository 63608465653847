import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AppContext, API } from "../utils";
import toasts from "react-hot-toast";

import { ProgressBar } from "primereact/progressbar";

import moment from "moment";

const DataTables = () => {
  const [state, setState] = React.useContext(AppContext);
  const [status, setStatus] = useState("");

  const [ads, setAds] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const isMounted = useRef(false);

  // useEffect(() => {
  //   if (isMounted.current) { 
  //     const summary =
  //       expandedRows !== null ? "All Ads Expanded" : "All Ads Collapsed";
  //     toast.current.show({
  //       severity: "success",
  //       summary: `${summary}`,
  //       life: 3000,
  //     });
  //   }
  // }, [expandedRows]);

  const loadAds = async () => {
    setStatus("loading");
    try {
      const res = await API.ads.get();
      if (res.data?.error) {
        toasts(
          "There have been too many calls from this ad-account,  Wait a bit and try again."
        );
      } else {
        setAds(res.data);
        setStatus("");
      }
    } catch (e) {
      console.log(e);
      toasts("There have been too many calls from this ad-account");
    }
  };

  useEffect(() => {
    isMounted.current = true;
    // console.log("state", state);

    if (state.account && state.loaded) {
      loadAds();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowExpand = (event) => {
    // console.log(event.data.ads.id, "event");
    toast.current.show({
      severity: "info",
      summary: "Ad Expanded",
      detail: event.data.ads.name,
      life: 3000,
    });
  };

  const onRowCollapse = (event) => {
    toast.current.show({
      severity: "success",
      summary: "Ad Collapsed",
      detail: event.data.ads.name,
      life: 3000,
    });
  };

  const expandAll = () => {
    let _expandedRows = {};
    ads.forEach((p) => (_expandedRows[`${p.id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const statusAdTemplate = (rowData) => {
    return (
      <span className="inline capitalize">
        {rowData.ads.effective_status.toLowerCase() == "active" ? (
          <span className="ml-2 font-bold text-sm">
            <Badge severity="success"></Badge>{" "}
            {rowData.ads.effective_status.toLowerCase().replace("_", " ")}
          </span>
        ) : (
          <span className="ml-2 font-bold text-sm">
            <Badge severity="danger"></Badge>{" "}
            {rowData.ads.effective_status.toLowerCase().replace("_", " ")}
          </span>
        )}
      </span>
    );
  };

  const statusConfigTemplate = (rowData) => {
    return (
      <span className="inline capitalize">
        {rowData.ads.configured_status.toLowerCase() == "active" ? (
          <span className="ml-2 text-sm font-bold">
            <Badge severity="success"></Badge>{" "}
            {rowData.ads.configured_status.toLowerCase().replace("_", " ")}
          </span>
        ) : (
          <span className="ml-2 text-sm font-bold ">
            <Badge severity="danger"></Badge>{" "}
            {rowData.ads.configured_status.toLowerCase().replace("_", " ")}
          </span>
        )}
      </span>
    );
  };

  const imageBodyTemplate = (rowData) => {
    if (!rowData.creative[0].thumbnail_url) return <h1>-</h1>;
    return (
      <img
        onClick={() => console.log(rowData.creative[0].body)}
        src={`${rowData.creative[0].thumbnail_url}`}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.creative[0].thumbnail_url}
        className="product-image rounded-md cursor-pointer"
      />
    );
  };

  const adNameTemplate = (rowData) => {
    return <h1 className="font-bold text-sm">{rowData.ads.name}</h1>;
  };

  const creationTimeTemplate = (rowData) => {
    return (
      <h1>
        {moment(new Date(rowData.ads.created_time)).format(
          "MMMM Do YYYY, h:mm:ss a"
        )}
      </h1>
    );
  };
  const updateTimeTemplate = (rowData) => {
    return (
      <h1>
        {moment(new Date(rowData.ads.updated_time)).format(
          "MMMM Do YYYY, h:mm:ss a"
        )}
      </h1>
    );
  };

  const objectiveTemplate = (rowData) => {
    return (
      <h1 className="capitalize text-sm font-bold">
        {rowData.insights.objective
          ? rowData.insights.objective.toLowerCase().replace("_", " ")
          : "-"}
      </h1>
    );
  };

  const reachTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.insights.reach ? rowData.insights.reach : "-"}
      </h1>
    );
  };

  const clicksTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.insights.clicks ? rowData.insights.clicks : "-"}
      </h1>
    );
  };

  const ctrTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.insights.ctr
          ? `${(rowData.insights.ctr * 1).toFixed(4)} %`
          : "-"}
      </h1>
    );
  };

  const thrupPlayTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.insights.video_play_actions
          ? rowData.insights.video_play_actions[0].value
          : "-"}
      </h1>
    );
  };

  const impressionsTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.insights.impressions ? rowData.insights.impressions : "-"}
      </h1>
    );
  };

  const frequencyTemplate = (rowData) => {
    return (
      <h1 className="text-sm font-bold">
        {rowData.insights.frequency
          ? (rowData.insights.frequency * 1).toFixed(2)
          : "-"}
      </h1>
    );
  };

  const pubPlatformTemplate = (rowData) => {
    return (
      <h1 className="capitalize text-sm font-bold">
        {rowData.ads.targeting.publisher_platforms
          ? rowData.ads.targeting.publisher_platforms[0]
          : "-"}
      </h1>
    );
  };

  const rowExpansionTemplate = (data) => {
    // console.log("data", data);
    return (
      <div className="orders-subtabl">
        <h5 className="text-orange-500 font-bold text-xl mb-2">
          Insights for {data.ads.name}
        </h5>
        <DataTable value={[data]} responsiveLayout="scroll">
          {/* <Column  field="data.ads.name" header="Impressions" body={data.insights.impressions ? data.insights.impressions : "-"}></Column> */}
          <Column
            headerClassName="w-72"
            field="ads.updated_time"
            header="Last Update"
            body={moment(new Date(data.ads.updated_time)).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
          ></Column>
          <Column
            headerClassName="w-72"
            field="insights.adset_name"
            header="Adset name"
            body={data.insights.adset_name ? data.insights.adset_name : "-"}
          ></Column>
          <Column
            headerClassName="w-72"
            field="insights.campaign_name"
            header="Compaign name"
            body={
              data.insights.campaign_name
                ? data.insights.campaign_name.replace("_", " ")
                : "-"
            }
          ></Column>
          <Column
            bodyClassName="font-bold"
            field="insights.inline_link_click_ctr"
            headerClassName="text-sm"
            header="Click Ctr"
            body={
              data.insights.inline_link_click_ctr
                ? `${(data.insights.inline_link_click_ctr * 1).toFixed(4)} %`
                : "-"
            }
          ></Column>
          {data.insights.actions
            ? data.insights.actions.map((e, i) =>
                e.action_type.length < 15 ? (
                  <Column
                    bodyClassName="font-bold"
                    headerClassName="capitalize text-sm"
                    key={i}
                    field={e.value}
                    header={
                      e.action_type && e.action_type.length < 15
                        ? e.action_type
                            .replace("_", " ")
                            .replace(".", " ")
                            .replace("_", " ")
                        : null
                    }
                    body={
                      e.action_type && e.action_type.length < 15 && e.value
                        ? e.value
                        : null
                    }
                  ></Column>
                ) : null
              )
            : null}
          <Column
            field="insights.date_start"
            headerClassName=" text-sm"
            header="Reports start"
            body={data.insights.date_start ? data.insights.date_start : "-"}
          ></Column>
          <Column
            headerClassName=" text-sm"
            field="insights.date_stop"
            header="Reports end"
            body={data.insights.date_stop ? data.insights.date_stop : "-"}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const header = (
    <div className="table-header-container flex gap-4">
      <Button
        icon="pi pi-plus"
        label="Expand All"
        onClick={expandAll}
        className="mr-2"
      />
      <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} />
      <div className="table-header absolute right-7">
        <Button onClick={loadAds} icon="pi pi-refresh" />
      </div>
    </div>
  );

  // console.log("ads", ads);

  return (
    <div className="datatable-rowexpansion-demo">
      <Toast ref={toast} />

      <div className="card">
        {status == "loading" ? (
          <>
            {" "}
            <ProgressBar
              color="rgb(249 115 22)"
              mode="indeterminate"
              style={{ height: "2px" }}
            ></ProgressBar>
            <p className="text-center text-orange-500 mt-2 font-bold">
              {" "}
              Loading data, this may take up to 30 seconds ...
            </p>
          </>
        ) : (
          <DataTable
            paginator
            className="border-t border-orange-500 overflow-y-auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={5}
            rowsPerPageOptions={[5, 25, 70]}
            value={ads}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            header={header}
          >
            <Column expander style={{ width: "3em" }} />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="ads.name"
              header="Ad name"
              headerClassName="w-64"
              sortable
              body={adNameTemplate}
            />
            <Column header="Ad image" body={imageBodyTemplate} />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"ads.created_time"}
              header="Created time"
              sortable
              body={creationTimeTemplate}
            />
              <Column
                // headerStyle={{ color: "rgb(249 115 22)" }}
                field={"ads.targeting.publisher_platforms"}
                header="Ad platform"
                body={pubPlatformTemplate}
                sortable
              />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="ads.effective_status"
              header="Status"
              sortable
              body={statusAdTemplate}
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="ads.configured_status"
              header="Config status"
              sortable
              body={statusConfigTemplate}
            />
            {/* <Column
              field="ads.updated_time"
              header="Last Update"
              body={updateTimeTemplate}
              sortable
            /> */}
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field="insights.objective"
              header="Objective"
              body={objectiveTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"insights.reach"}
              header="Reach"
              body={reachTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"insights.impressions"}
              header="Impressions"
              body={impressionsTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"insights.frequency"}
              header="Repetition"
              body={frequencyTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"insights.clicks"}
              header="Clicks"
              body={clicksTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"insights.ctr"}
              header="Ctr"
              body={ctrTemplate}
              sortable
            />
            <Column
              // headerStyle={{ color: "rgb(249 115 22)" }}
              field={"insights.video_play_actions"}
              header="Thru Play"
              body={thrupPlayTemplate}
              sortable
            />
            {/* <Column field="insights.campaign_name" header="Compaign name" sortable /> */}
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default DataTables;
