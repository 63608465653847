import React, { useState, useEffect } from "react";
import InfoCard from "./InfoCard";
import DataTable from "./DataTables";
import GoogleDataTable from "./googleAds/DataTable"

import { ProgressBar } from "primereact/progressbar";

import { AppContext, API } from "../utils";

import logoOrange from "../images/logoOrange.svg";
import fbIcon from "../images/fbIcon.png";
import googleAdsIcon from "../images/gAds.png";

import { InputSwitch } from "primereact/inputswitch";
import GoogleInfoCard from "./googleAds/GoogleInfoCard";

const Dashboard = () => {
  const [state, setState] = React.useContext(AppContext);
  const loaded = state && state.account;
  const [checked2, setChecked2] = useState(false);

  useEffect(() => {
    if (!loaded) API.init(state, setState);
    if (!checked2) {

      setState((s) => {
        return { ...s, adsFor: "facebook" }
      })
    }

  }, []);

  if (!loaded)
    return (
      <ProgressBar
        color="rgb(249 115 22)"
        mode="indeterminate"
        style={{ height: "7px" }}
      ></ProgressBar>
    );



  const siwtch = (e) => {
    setChecked2(e.value)

    if (!checked2) {

      setState((s) => {
        return { ...s, adsFor: "google" }
      })
    }

    if (checked2) {
      setState((s) => {
        return { ...s, adsFor: "facebook" }
      })
    }
  }

  // console.log("state", state);
  // console.log(checked2);

  return (
    <div className="o-dashboard">
      <div className="z-10 w-full fixed top-0 opacity-80 text-center shadow-2xl p-4  text-5xl text-orange-500 font-bold pt-1  bg-gray-100 border-b border-orange-500">
        <span className="inline float-left text-xs font-medium ">
          {state.account.name}
        </span>
        <h1 className="z-50 text-4xl opacity-100 mr-28">Orange Ads-Tool</h1>
        {/* <span><img src={logoOrange} alt="" /></span> */}
      </div>

      <div className="flex items-center gap-6 justify-center pt-20">
        {/* <img src={fbIcon} className="h-8 w-8" alt="" />
        <InputSwitch
          className="w-14"
          checked={checked2}
          onChange={(e) => siwtch(e)}
        />
        <img src={googleAdsIcon} className="h-8 w-8" alt="" /> */}
      </div>
      {
        state.adsFor == "facebook" &&
        <>
          <div className="p-4 pt-4">
            <InfoCard />
          </div>
          <div className=" ml-4 font-bold text-gray-600 ">
            - : Data not available or newly created and will be available soon
          </div>
          <div className="p-4 fle justify-center mx-auto">
            <DataTable />
          </div>
        </>
      }
      {
        state.adsFor == "google" &&
        <>
          <div className="p-4 pt-4">
            <GoogleInfoCard />
          </div>
          {/* <div className=" ml-4 font-bold text-gray-600 ">
            - : Data not available or newly created and will be available soon
          </div> */}
          <div className="p-4  justify-center mx-auto">
            <GoogleDataTable />
          </div>
        </>
      }
    </div>
  );
};

export default Dashboard;
