import axios from 'axios';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
const BASE_URL = process.env.REACT_APP_API_URL



let API = {};

API.google = {
    info : function () {
        return axios.get(BASE_URL+"/google/account/info")
    },

    ads : function (from_date, to_date) {
        return axios.post(BASE_URL+"/google/ads",{from_date, to_date})
    }
}



API.info = {
    get : function () {
        return axios.get(BASE_URL+"/account/info")
    }
}


API.ads = {
    get : function () {
        return axios.get(BASE_URL+"/account/ads/insights")
    }
}





API.init = async (state, setState) => {
    
   API.info.get().then((r) => console.log(r) || setState(s=>({...s, account:r.data, loaded:true, reportingPeriod : [dayjs().subtract(1, 'day').toDate(), dayjs().toDate()]})))
    .catch(r=>{
        if (r?.response?.status==401) {
            toast('Votre session a expirée.');
            return;
        }        
        console.log('r ==>', r);
        console.error(r);
        toast('Une erreur est survenue. Merci de réessayer.');
    });
}


export default API;