import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import moment from "moment";

import gIcon from "../../images/gAds.png";

import { AppContext, API } from "../../utils";
import { ProgressBar } from "primereact/progressbar";


const GoogleInfoCard = () => {
    const [state, setState] = React.useContext(AppContext);
    const [info, setInfo] = useState("second")
    const [status, setStatus] = useState("loading")

    // console.log("state",state);

    const googleInfo = async () => {
        try {
            setStatus("loading")
            const infos = await API.google.info()
            console.log(infos.data[0].customer)
            setInfo(infos.data[0].customer);
            setStatus("")
        }
        catch (e) {
            console.log(e);
        }
    }
    

    useEffect(() => {
        googleInfo()
    }, [])

    if (status == "loading")
        return (
            <ProgressBar
                color="rgb(249 115 22)"
                mode="indeterminate"
                style={{
                    height: "1px",
                    marginTop : "120px"
                }}
            ></ProgressBar>
        );

    return (
        <div className="flex">
            <Card
                className="shadow-2xl border border-orange-500"
                title={() => (
                    <h1 className="flex items-center">
                        <img className="h-8 w-8 mr-1" src={gIcon} alt="" />
                        Google Ads account info
                    </h1>
                )}
                style={{ width: "25rem", marginBottom: "2em" }}
            >
                <div className="m-0" style={{ lineHeight: "1.5" }}>
                    <h1 className="font-bold">
                        Account Name :{" "}
                        <span className="inline font-light">{info.descriptive_name}</span>
                    </h1>
                    <h1 className="font-bold mt-2">
                        Account ID :{" "}
                        <span className="inline font-light">
                            {info.id}
                        </span>
                    </h1>
                </div>
            </Card>

            <div className="text-white bg-primary absolute right-4 px-8 py-4 rounded-lg shadow border border-orange-500">
                <h1 className="font-bold text-gray-600">
                    Account Status :
                    <span className="inline">
                        {info.status == 2 ? (
                            <span className="ml-2">
                                <Badge severity="success"></Badge> Active
                            </span>
                        ) : (
                            <span className="ml-2">
                                <Badge severity="danger"></Badge> Not Active
                            </span>
                        )}
                    </span>
                </h1>
            </div>
        </div>
    )
}

export default GoogleInfoCard