import React, { useState, useEffect } from "react";
import { AppProvider } from "./utils/Context";
import { Toaster } from "react-hot-toast";


import "./App.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";

import Dashboard from "./cmp/Dashboard";
import Login from "./cmp/Login";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    const checkIfUserIsLoggedIn = () => {
      const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      
      setLoggedIn(isLoggedIn);
    };
  
    checkIfUserIsLoggedIn();
  }, []);
  

  const handleLogin = () => {
    // Logique de connexion réussie
    
    setLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
  };
  
  

  return (
    <AppProvider>
      <Toaster
        position="top-center"
        toastOptions={{
          className:
            " text-white rounded-xl font-medium text-md bg-secondary",
          style: {
            padding: "15px",
            paddingRight: "70px",
            color:"#ffffff",
            background:"rgb(249 115 22)"
          },
        }}
      />
      <div className="App bg-gray-100 min-h-screen" >
        {loggedIn ? (
          <Dashboard />
        ) : (
          <Login handleLogin={handleLogin} />
        )}
      </div>
    </AppProvider>
  );
}

export default App;
