import React, { useState } from "react";

const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const checkCredentials = () => {
    const validUsername = "orange"; // Nom d'utilisateur valide
    const validPassword = "6f272ec0c0ce4761d37069df758221af"; // Mot de passe valide

    if (username === validUsername && password === validPassword) {
      handleLogin(); // Appeler la fonction handleLogin lorsque les identifiants sont corrects
    } else {
      setError(true); // Afficher une erreur si les identifiants sont incorrects
    }
  };

  return (
    <div className="login-container">
        <div>
      <h2>Connexion</h2>
      {error && <p className="error-message">Nom d'utilisateur ou mot de passe incorrect</p>}
      <input
        type="text"
        placeholder="Nom d'utilisateur"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Mot de passe"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={checkCredentials}>Se connecter</button>
      </div>
    </div>
  );
};

export default Login;
