import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import moment from "moment";

import fbIcon from "../images/fbIcon.png";

import { AppContext, API } from "../utils";

const InfoCard = () => {
  const [state, setState] = React.useContext(AppContext);

  return (
    <div className="flex">
      <Card
        className="shadow-2xl border border-orange-500"
        title={() => (
          <h1 className="flex items-center">
            <img className="h-8 w-8 mr-1" src={fbIcon} alt="" />
            Facebook Ads account info
          </h1>
        )}
        style={{ width: "25rem", marginBottom: "2em" }}
      >
        <div className="m-0" style={{ lineHeight: "1.5" }}>
          <h1 className="font-bold">
            Account Name :{" "}
            <span className="inline font-light">{state.account.name}</span>
          </h1>
          <h1 className="font-bold mt-2">
            Account ID :{" "}
            <span className="inline font-light">
              {state.account.account_id}
            </span>
          </h1>
          <h1 className="font-bold mt-2">
            Created Date :{" "}
            <span className="inline font-light">
              {moment(new Date(state.account.created_time)).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </h1>
        </div>
      </Card>

      <div className="text-white bg-primary absolute right-4 px-8 py-4 rounded-lg shadow border border-orange-500">
        <h1 className="font-bold text-gray-600">
          Account Status :
          <span className="inline">
            {state.account.account_status == 1 ? (
              <span className="ml-2">
                <Badge severity="success"></Badge> Active
              </span>
            ) : (
              <span className="ml-2">
                <Badge severity="danger"></Badge> Not Active
              </span>
            )}
          </span>
        </h1>
      </div>
    </div>
  );
};

export default InfoCard;
